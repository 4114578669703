* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Aileron-Regular";
  outline: none;
  scrollbar-width: thin;
  scrollbar-color: #cbcbcb rgba(0, 0, 0, 0);
}

html {
  height: 100%;
}

body {
  background-color: theme("colors.light.main");
}

input:disabled,
select:disabled {
  opacity: 0.9;
  cursor: not-allowed;
  background-color: rgba(235, 235, 235, 0.8);
  border-color: rgba(235, 235, 235, 0.8);
}

button:disabled,
button:disabled:hover {
  opacity: 0.4;
  cursor: not-allowed;
}

#healthcareBadge::after,
#patientsBadge::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.3);
  z-index: 0;
}

#patientsBadge:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-top: 55px solid #dbe5ed;
  border-left: 55px solid #d0d81d;
  width: 0;
}

#healthcareBadge:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-top: 55px solid #dbe5ed;
  border-right: 55px solid #34669a;
  width: 0;
}

@media (min-width: 768px) {
  #patientsBadge:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-top: 80px solid #dbe5ed;
    border-left: 80px solid #d0d81d;
    width: 0;
  }

  #healthcareBadge:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border-top: 80px solid #dbe5ed;
    border-right: 80px solid #34669a;
    width: 0;
  }
}
