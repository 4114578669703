@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./global.css');

@font-face {
  font-family: "Aileron-Regular";
  src: local("Aileron-Regular"),
   url("../assets/fonts/Aileron/Aileron-Regular.otf") format("truetype");
 }